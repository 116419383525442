.wrapper
  width: 100%
  height: 100%
  background: #fff
  box-shadow: 0 4px 9px rgba(0,0,0,0.4)
  border-radius: 16px
  padding: 24px
  box-sizing: border-box

.cover
  width: 142px
  height: 142px
  margin: 34px auto 23px auto
  border-radius: 8px
  display: block

.name
  color: #272928
  text-align: center
  font-size: 20px
  font-weight: 800
  margin-bottom: 32px

.item
  display: flex
  flex-flow: row nowrap
  justify-content: space-between
  align-items: center
  margin-bottom: 8px

.label
  color: #272928
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 22px
  max-width: 40%
  white-space: nowrap

.value
  color: #4F5150
  text-align: right
  font-size: 14px
  font-weight: 400
  line-height: 22px
  max-width: 40%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.close
  position: absolute
  cursor: pointer
  right: 24px
  top: 24px
  background: #F8F8F8
  border-radius: 50%

.link
  text-decoration: none
  color: #7492EF
  font-weight: normal
  font-size: 14px
  margin-left: 4px
