.dialog-request-tobe-issuer {
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);

  --color-input-bg: #F8F8F8;
}

.title {
  color: var(--Settings-first-title, #222);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 16px;
}

.message {
  color: var(--text-4-headline, #272928);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.456px;
  margin-bottom: 8px;
}

.input {
  margin-bottom: 12px;
}
