.discover-page {
  background: #F8F9F8;
  overflow: auto;

  :global {
    .swiper-slide {
      padding: 3px;
      box-sizing: border-box;
    }

    .swiper-pagination-bullet {
      width: 20px;
      height: 5px;
      border-radius: 3px;
      transform: translateY(5px);
    }
  }

  .center {
    max-width: 1024px;
    padding: 0 12px;
    margin: 0 auto;
  }

  .page-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--color-text-main);
    margin: 16px 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    a {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-decoration: none;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      color: var(--color-text-main);

      &:hover {
        text-decoration: underline;
      }

      svg {
        margin-left: 8px;
      }
    }

    @media (max-width: 650px) {
      & {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
      }
    }
  }
}

.card-featured {
  min-height: 216px;
  border-radius: 8px;
  box-shadow: 0 0 5px 0 #0000001F;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  color: var(--color-text-main);

  .cover {
    min-width: 318px;
    min-height: 184px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    flex-grow: 0;

    .default-cover {
      width: 100%;
      min-width: 318px;
      height: 184px;
      background: linear-gradient(45deg, #ffddf5, #cdfffb);
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      text-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
    }

    img {
      min-width: 100%;
      width: auto;
      min-height: 184px;
      height: 184px;
      object-fit: cover;
    }
  }

  .detail {
    flex: 1;

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-decoration: none;
    }

    .des {
      //styleName: EN/16-Regular;
      font-size: 16px;
      height: 48px;
      font-weight: 400;
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 30px;
    }

    @media (max-width: 650px) {
      .des {
        display: none;
      }
    }
  }

  .item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;

    div {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    i {
      flex-grow: 0;
      transform: translateY(4px);
      margin-right: 12px;
    }

    img {
      width: 16px;
      height: 16px;
      transform: translateY(4px);
      border-radius: 50%;
      margin-right: 12px;
    }
  }

  @media(max-width: 650px) {
    & {
      display: flex;
      flex-flow: column nowrap;


      .cover {
        margin-bottom: 12px;
        width: 100%;
        height: auto;
        margin-right: 0;
        max-width: initial;
      }
    }
  }
}

.popup-city-list {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-right: -12px;
  margin-bottom: -12px;

  &.season {
    margin-bottom: 0;
  }


  :global(>a) {
    min-width: calc((100% - 12px * 3) / 4);
    max-width: calc((100% - 12px * 3) / 4);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @media(max-width: 1000px) {
    :global(>a) {
      min-width: calc((100% - 12px * 2) / 3);
      max-width: calc((100% - 12px * 2) / 3);

      &:nth-child(4n) {
        margin-right: 12px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  @media(max-width: 650px) {
    & {
      flex-flow: row wrap;
    }

    :global(>a) {
      min-width: calc((100% - 12px * 1) / 2);
      max-width: calc((100% - 12px * 1) / 2);

      &:nth-child(3n) {
        margin-right: 12px;
      }

      &:nth-child(2n) {
        margin-right: 0!important;
      }
    }
  }
}

.group-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-right: -12px;
  margin-bottom: 44px;

  > a {
    border-radius: 8px;
    background: var(--Fill-0-White, #FFF);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
    padding: 16px;
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 12px;
    text-decoration: none;
    position: relative;
    transition: all 0.12s linear;

    &:hover {
      transform: translateY(-8px)
    }

    .cover {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }

    .name {
      color: var(--Text-4-Headline, #272928);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 9px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .detail {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: var(--Text-4-Headline, #272928);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 9px;
    }
  }


  :global(>a) {
    min-width: calc((100% - 12px * 3) / 4);
    max-width: calc((100% - 12px * 3) / 4);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @media(max-width: 1000px) {
    :global(>a) {
      min-width: calc((100% - 12px * 2) / 3);
      max-width: calc((100% - 12px * 2) / 3);

      &:nth-child(4n) {
        margin-right: 12px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  @media(max-width: 650px) {
    & {
      flex-flow: row wrap;
    }

    :global(>a) {
      min-width: calc((100% - 12px * 1) / 2);
      max-width: calc((100% - 12px * 1) / 2);

      &:nth-child(3n) {
        margin-right: 12px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.popup-city-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-right: -12px;
  margin-bottom: 44px;


  :global(>div) {
    min-width: calc((100% - 12px * 3) / 4);
    max-width: calc((100% - 12px * 3) / 4);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @media(max-width: 1000px) {
    :global(>div) {
      min-width: calc((100% - 12px * 2) / 3);
      max-width: calc((100% - 12px * 2) / 3);

      &:nth-child(4n) {
        margin-right: 12px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  @media(max-width: 650px) {
    & {
      flex-flow: row wrap;
    }

    :global(>div) {
      min-width: calc((100% - 12px * 1) / 2);
      max-width: calc((100% - 12px * 1) / 2);

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 12px;
      }
    }
  }
}

.goto-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-right: -12px;
  margin-bottom: -12px;

  > a {
    border-radius: 8px;
    background: var(--Fill-0-White, #FFF);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    padding: 16px;
    height: 144px;
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 12px;
    text-decoration: none;
    transition: all 0.12s linear;

    &:hover {
      transform: translateY(-8px)
    }

    > svg {
      margin-bottom: 9px;
    }

    img {
      width: 24px;
      height: 24px;
      margin-bottom: 16px;
    }

    .name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--Text-4-Headline, #272928);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }


  :global(>a) {
    min-width: calc((100% - 12px * 3) / 4);
    max-width: calc((100% - 12px * 3) / 4);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  @media(max-width: 1000px) {
    :global(>a) {
      min-width: calc((100% - 12px * 2) / 3);
      max-width: calc((100% - 12px * 2) / 3);

      &:nth-child(4n) {
        margin-right: 12px;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  @media(max-width: 650px) {
    & {
      flex-flow: row wrap;
    }

    :global(>a) {
      min-width: calc((100% - 12px * 1) / 2);
      max-width: calc((100% - 12px * 1) / 2);

      &:nth-child(3n) {
        margin-right: 12px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

.page-sub-title {
  background: linear-gradient(90deg, #EAEEF6 0%, rgba(234, 238, 246, 0.00) 99.89%);
  padding: 0 16px;
  color: var(--Text-4-Headline, #272928);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  margin: 16px 0;
}

.highlight-events {
  margin-bottom: 50px;
}

.links {
  display: flex;
  flex-flow: row nowrap;
  margin: 18px 0;

}

.link-item {
  border-radius: var(--Radii-radius-button, 6px);
  background: var(--Fill-2-Medium, #EEF1EF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 50px;
  flex: 1;
  padding: 0 12px;

  color: var(--Text-4-Headline, #272928);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;

    &:hover {
        background: var(--Fill-2-Dark, #EDEDED);
    }
}

.link-text {
  margin: 0 8px;
}

.featured {
  margin-bottom: 44px;
}

.highlight-events {
  display: flex;
  flex-flow: row wrap;

  :global(.event-card-discover) {
    width: calc(50% - 6px);
    flex-shrink: 0;
    margin-bottom: 12px;
    transition: all 0.12s linear;

    &:hover {
      transform: translateY(-8px)
    }

    &:nth-child(2n) {
      margin-left: 12px;
    }
  }
}


@media (max-width: 450px) {
  .page-title {
    font-size: 20px!important;
    margin: 18px 0!important;
  }

  .page-sub-title {
    font-size: 16px;
  }

  .highlight-events {
    flex-flow: column nowrap;
    :global(.event-card-discover) {
      width: auto;
      flex: 1;
      margin-left: 0!important;
    }

  }

  .link-item {
    font-size: 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}




