.dialog {
  background: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #c0c0c0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 20px;
}

.des {
  font-weight: 600;
  margin: 16px 0;
  font-size: 12px;
}

.primary {
  color: #7ed8ba;
}

.btns {
  display: flex;
  flex-flow: row nowrap;

  button:nth-child(2) {
    margin-left: 16px;
  }
}

.text {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 12px;
  }
}
