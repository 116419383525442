.group-comment {
  padding-bottom: 50px;
}

.input {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 30px;


  img {
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }

  textarea {
    border-radius: 8px !important;
    background: var(--Fill-2-Medium, #F5F8F6) !important;
    border: 0;

    &::placeholder {
      color: #C3C7C3;
    }
  }

  :global(div[data-baseweb="textarea"]) {
    border: 0;
  }
}

.wrapper {
  background: var(--Fill-2-Medium, #F5F8F6) !important;
  border-radius: 8px !important;
  flex: 1;
  display: flex;
  flex-flow: nowrap column;
  align-items: flex-end;

  button {
    opacity: 0;
    pointer-events:  none;
  }
}

.wrapper-active {
  background: var(--Fill-2-Medium, #F5F8F6) !important;
  border-radius: 8px !important;
  flex: 1;
  display: flex;
  flex-flow: nowrap column;
  align-items: flex-end;

  button {
    background: var(--Fill-2-Medium, #F5F8F6) !important;
    display: inline-flex;
    pointer-events: auto;
    color: #6CD7B2;
    opacity: 1;
  }
}

.comment-item {
  margin-bottom: 36px;
  .info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .name {
      color: var(--color-text-main);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }

    .create-time {
      color: var(--color-text-sub);
      font-size: 16px;
      margin-left: 8px;
    }
  }

  .content {
    margin-top: 16px;
    color: var(--Text-4-Headline, #272928);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

